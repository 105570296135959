body {
  font-family: "Poppins", sans-serif;
}

.scrollbar-hidden::-webkit-scrollbar {
  width: 0;
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none; /* Untuk Internet Explorer dan Edge */
  scrollbar-width: none; /* Untuk Firefox */
}

.iframe-youtube {
  border-radius: 0.375rem;
}
