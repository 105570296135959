@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Collingar";
    src: url("/public/assets/fonts/Collingar.otf") format("opentype");
  }
  @font-face {
    font-family: "Edith-Regular";
    src: url("/public/assets/fonts/Edith-Regular.ttf") format("opentype");
  }
  @font-face {
    font-family: "Forum-Regular";
    src: url("/public/assets/fonts/Forum-Regular.ttf") format("opentype");
  }
  @font-face {
    font-family: "Poppins-Regular";
    src: url("/public/assets/fonts/Poppins-Regular.otf") format("opentype");
  }
}
