/* HTML: <div class="loader"></div> */
.loader {
    width: 32px;
    height: 16px;
    display: flex;
    animation: l9-0 1s infinite;
  }
  .loader:before,
  .loader:after {
    content: "";
    flex: 1;
    background: #3FB8AF;
    transform-origin: top right;
    animation: l9-1 1s infinite;
  }
  .loader:after {
    background: #FF3D7F;
    transform-origin: top left;
    --s:-1;
  }
  @keyframes l9-0 {
     100% {transform: translateY(100%)}
  }
  @keyframes l9-1 {
     100% {transform: rotate(calc(var(--s,1)*90deg))}
  }